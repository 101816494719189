import PropTypes from 'prop-types'
import React, {useState } from 'react'

import { Link } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden';
 import MenuComp from './MenuComp';

import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import Grid from '@material-ui/core/Grid';

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    background: '#000000',
  },
  headerLinks: {
    marginTop: '4px'
  },
  heading: {
    display: 'inline',
    fontFamily: 'Shadows Into Light',
    fontWeight: 'bold',
    fontSize: '24px',
    cursor: 'pointer',
    "&:hover": {
      color: "#FF0000"
    }
  },
  hamburger: {
    marginTop: 6,
    marginRight: 10,
    fontSize: "34px"
  },
  margins: {
    marginRight: '10px'
  },
  test: {
    backgroundColor: '#FF0000',
    // height: '3px'
  },
  listItems: {
    display: 'inline',
    fontSize: '12px'
  },
  contactInfo: {
    marginTop: '2px'
  },
  socialIcons: {
    cursor: 'pointer',
    color: '#FFFFFF',
    "&:hover": {
      color: "#000000",
    }
  },
  siteTitle: {
    color: '#FFFFFF'
  }
}))

const Header = ({ siteTitle, siteNumber }) => {
  const classes = useStyles()

  const theme = useTheme()
  const [open, setOpen] = React.useState(false)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={1}
        className={classes.appBar}
      >
        <Toolbar>
          <Grid container justify="space-between">
            <Grid item xs={10} md={7} lg={7}>
              <Link to={'/'} className={classes.siteTitle}>
                <Typography variant="h6" color="inherit" className={classes.heading}>
                  {siteTitle}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={1} md={3} lg={3}>
              <Hidden smDown>
                <Grid container justify="space-evenly">
                  <Grid item className={classes.headerLinks}>
                    <Link to="/" style={{color: 'white'}}>
                      <Typography variant="inherit" color="inherit" className={classes.heading}>
                        Home
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item className={classes.headerLinks}>
                    <Link to="/services" style={{color: 'white'}}>
                      <Typography variant="inherit" color="inherit" className={classes.heading}>
                        Services
                      </Typography>
                    </Link>
                  </Grid>
                  <Grid item className={classes.headerLinks}>
                    <Link to="/contact" style={{color: 'white'}}>
                      <Typography variant="inherit" color="inherit" className={classes.heading}>
                        Contact
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Hidden>

            </Grid>
            <Hidden mdUp>
              <Grid item xs={1}>
                <Hidden mdUp>
                  <MenuComp/>
                </Hidden>
              </Grid>
            </Hidden>

          </Grid>
        </Toolbar>
        <div className={classes.test}>
          <Grid container justify={'space-evenly'}>
            <Grid item className={classes.contactInfo}>
              <Typography variant={'subtitle2'}>
                {siteNumber}
              </Typography>
            </Grid>
            <Hidden mdDown>
              <Grid item className={classes.contactInfo}>
                <Typography variant={'subtitle2'}>
                  3765 General DeGaulle Ave. New Orleans, La 70114 | 1555 Manhattan Blve. Ste. 1 Harvey, La 70058
                </Typography>
              </Grid>
            </Hidden>
            <Grid item xs={3} lg={1}>
              <Grid container justify={'space-between'}>
                <Grid item>
                  <a href={"https://www.facebook.com/GraysTExpress/"} className={classes.socialIcons}>
                    <FacebookIcon />
                  </a>
                </Grid>
                <Grid item>
                  <a href={"https://www.instagram.com/graystaxexpress/"} className={classes.socialIcons}>
                    <InstagramIcon />
                  </a>
                </Grid>
                <Grid item>
                  <TwitterIcon className={classes.socialIcons}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </AppBar>

    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
