import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles} from '@material-ui/core/styles/makeStyles'
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

const useStyles = (theme => ({
  socialIcons: {
    color: "#FF0000",
    "&:hover": {
      color: "#FF0000",
    }
  }
}))

const SocialIconComp = () => {
  const classes = useStyles();

  return (
    <div>
      <ul>
        <li style={{ display: 'inline-block', margin: '0px 15px' }}>
          <a href={"https://www.facebook.com/GraysTExpress/"} className={classes.socialIcons}>
            <FacebookIcon/>
          </a>
        </li>
        <li style={{ display: 'inline-block', margin: '0px 15px' }}>
          <a href={"https://www.instagram.com/graystaxexpress/"} className={classes.socialIcons}>
            <InstagramIcon/>
          </a>
        </li>
        <li style={{ display: 'inline-block', margin: '0px 15px' }}>
          <a href={"https://www.twitter.com"} className={classes.socialIcons}>
            <TwitterIcon/>
          </a>
        </li>
      </ul>
    </div>
    )

}

export default SocialIconComp;
