/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import SocialIconComp from './SocialLinks';
import Grid from '@material-ui/core/Grid';
import Header from './header'
import './layout.css'

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              number
            }
          }
        }
      `}
      render={data => (
        <div style={{ minHeight: '100vh', backgroundColor: '#FFF' }}>
          <Header siteTitle={data.site.siteMetadata.title} siteNumber={data.site.siteMetadata.number} />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: '100%',
              // padding: `0px 1.0875rem 1.45rem`,
              // paddingTop: '-275',
            }}
          >
            <main>{children}</main>
            <footer style={{ backgroundColor: '#F4F4F4', width: "100%", height: '100px'}}>

              <div style={{width: "100%", textAlign: 'center', paddingTop: '40px'}}>
                <SocialIconComp/>
                <span style={{color: '#FF0000'}}>© {new Date().getFullYear()}, Grays Tax Express LLC. All Right Reserved</span>
              </div>

            </footer>
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
