import React,{ useState } from 'react';
import { Link } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Logo from './white_logo'
const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
  hamburger: {
    marginTop: '6px',
    marginRight: '5px',
    fontSize: "34px"
  },
  list: {
    width: drawerWidth,
  }
}))
const MenuComp = () => {
  const classes = useStyles();
  const [state, setRight ] = useState({
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setRight({[side]: open});
  }

  const sideList = side => (
    <div onClick={toggleDrawer(side, false)}>
      <div style={{width: `100%`}}>
        <Logo/>
      </div>

      <List>
        {[
          {name: "Home", link: `/`},
          {name: "Services", link: `/services`},
          {name: "Contact", link: `/contact`}
          ].map(obj => (
          <Link to={obj.link}>
            <ListItem button autofocus={true} key={obj.name}>
              <ListItemText primary={obj.name}></ListItemText>
            </ListItem>
          </Link>
          ))}

      </List>
    </div>
  )

  return (
    <div>
      <MenuIcon className={classes.hamburger} onClick={toggleDrawer('right', true)}/>
      <Drawer open={state.right} onClose={toggleDrawer("right", false)} className={classes.list}>
        {sideList("right")}
      </Drawer>
    </div>
  )
}

export default MenuComp;
